import React from "react"
import { Link, navigate } from "gatsby"
import "twin.macro"

const BackButton = () => {
  const isBrowser = typeof window !== "undefined"

  return (
    <>
      {isBrowser && window.history.length > 2 ? (
        <button
          tw="bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
          title="Zurück"
          onClick={() => navigate(-1)}
        >
          zurück
        </button>
      ) : (
        <Link
          tw="bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
          title="Zurück"
          to="/"
        >
          zurück
        </Link>
      )}
    </>
  )
}

export default BackButton
